import { createStylesParams, StyleParamType, wixColorParam, wixFontParam } from '@wix/tpa-settings';

export enum Layout {
  IconAndText = 'iconAndText',
  Icon = 'icon',
  Text = 'text',
}

export enum IconPosition {
  Start = 'start',
  End = 'end',
}

export enum Alignment {
  Start = 'start',
  Center = 'center',
  End = 'end',
}

export type IStylesParams = {
  backgroundColor: StyleParamType.Color;
  textFont: StyleParamType.Font;
  textColor: StyleParamType.Color;
  borderColor: StyleParamType.Color;
  borderWidth: StyleParamType.Number;
  cornerRadius: StyleParamType.Number;
  backgroundColorHover: StyleParamType.Color;
  textColorHover: StyleParamType.Color;
  borderColorHover: StyleParamType.Color;
  layout: StyleParamType.String;
  iconPosition: StyleParamType.String;
  iconSize: StyleParamType.Number;
  alignment: StyleParamType.String;
  gap: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  textFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  cornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 8,
  },
  backgroundColorHover: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  textColorHover: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  borderColorHover: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  layout: {
    type: StyleParamType.String,
    getDefaultValue: ({ isMobile }) => (isMobile ? Layout.Icon : Layout.IconAndText),
  },
  iconPosition: {
    type: StyleParamType.String,
    getDefaultValue: () => IconPosition.Start,
  },
  iconSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
  alignment: {
    type: StyleParamType.String,
    getDefaultValue: () => Alignment.Start,
  },
  gap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 12,
  },
});
